import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { bannerULA, columnCards, sliderCompanies, subpageContent } from "../utils/products/subpageInformation";
import { bannerCMS } from "../utils/generalInformation";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";




// markup
const ExcessProtection = () => {


    return (
        <Layout
            SEOTitle={"Excess Protection - United Legal Assistance"}
            SEODescription={"Excess Protection Product. Your clients look to you to help them manage risk. Excess protection does exactly that. By accepting high excess, it is often possible to negotiate a lower premium."}
            footerDisclaimer={""}>

            <Banner props={bannerULA} />

            <InfoImage props={subpageContent} origin="excess" />

            <Banner props={bannerCMS} />

            <Comparison props={columnCards} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default ExcessProtection